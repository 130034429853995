<!--  -->
<template>
    <el-dialog :visible.sync="show" title="选择素材" :close-on-click-modal="false" width="550px">
        <div>
            <div class="ls_flex_between">
                <div class="ls_flex">
                    <el-select size="small" v-model="query.classifyId2" style="width: 80px" @change="getClassifyArr3()">
                        <el-option v-for="item in classifyArr2" :key="item.id" :label="item.name" :value="item.id">
                            {{ item.name }}
                        </el-option>
                    </el-select>
                    <el-select v-if="query.classifyId2" size="small" v-model="query.classifyId3" style="width: 100px; margin-left: 10px;">
                        <el-option v-for="item in classifyArr3" :key="item.id" :label="item.name" :value="item.id">
                            {{ item.name }}
                        </el-option>
                    </el-select>
                    <el-input size="small" v-model="query.name" placeholder="请填写关键词" style="width: 150px;margin: 0 10px;" />
                    <el-button size="small" plain @click="reLoadList()">
                        查询
                    </el-button>
                </div>
                <el-button type="text" @click="lookCheckFunc">{{ lookCheck ? '显示全部' : '显示已选' }}</el-button>
            </div>

            <div class="list-box">
                <div class="list-item" v-for="(item, index) in lookCheck ? checkedArr : list" :key="index" @click="checkItem(item)">
                    <div class="img-box bg-img" :style="'background-image:url(' + formatImg(item.coverImage) + ')'">
                        <i class="iconfont icon-xuanzhong" v-if="checkedArr.findIndex(cItem => cItem.id === item.id) > -1"></i>
                    </div>
                    <div class="fz14 tac">{{ item.name }}</div>
                </div>
            </div>

            <div v-if="total == 0" class="no_data">
                <div class="empty">
                    <i class="iconfont icon-queshengye_zanwushuju"></i>
                </div>
                <p class="tc">暂无数据</p>
            </div>

            <div class="page_part" v-if="!lookCheck">
                <el-pagination v-show="total > 0" :current-page="query.pageIndex" :page-size="query.pageSize" :total="total" @current-change="handleCurrentChange" />
            </div>

            <div class="dialog_foot">
                <el-button type="primary" @click="submitFunc">
                    确认
                </el-button>
                <el-button @click="show = false">取消</el-button>
            </div>
        </div>

    </el-dialog>
</template>
<script>
import Vue from "vue";

export default {
    components: {},
    props: {},
    data () {
        return {
            show: false,
            lookCheck: false,
            query: {
                name: "", // 素材名称 支持模糊搜索
                status: 1,  //状态 1-正常 2-下架
                startTime: "",
                endTime: "",
                publicType: "",
                classifyId1: "",
                classifyId2: "", // 二级分类id
                classifyId3: "",

                pageIndex: 1,
                pageSize: 10
            },

            total: 0,
            list: [],
            classifyArr2: [],
            classifyArr3: [],
            checkedArr: [],

            arr2Index: 0,
        };
    },
    computed: {},
    watch: {},
    created () { },
    mounted () { },
    methods: {
        handleCurrentChange (val) {
            this.query.pageIndex = val;
            this.getList();
        },
        formatImg (url) {
            return Vue.filter("imgFormat")(url)
        },
        showDialog (classifyId1, checkedArr = []) {
            this.show = true;
            this.query.classifyId1 = classifyId1;
            this.checkedArr = checkedArr;

            this.getList();
            this.getClassifyArr2();
        },
        getClassifyArr2 () {
            // 获取二级列表筛选项
            this.$ajax.get("/admin/classifyManage/queryByParentId", { params: { parentId: this.query.classifyId1 } }).then(res => {
                res.data.unshift({ "id": '', "name": "全部", "parentId": 1, "iconUrl": "", "color": "" });
                this.classifyArr2 = res.data;
            })
        },
        getClassifyArr3 () {
            this.query.classifyId3 = ""
            this.$ajax.get("/admin/classifyManage/queryByParentId", { params: { parentId: this.query.classifyId2 } }).then(res => {
                res.data.unshift({ "id": '', "name": "全部", "parentId": 1, "iconUrl": "", "color": "" });
                this.classifyArr3 = res.data;
            })
        },
        getList () {
            const { query } = this;
            this.$ajax.post("/material/queryList", query).then(res => {
                this.list = res.list;
                this.total = res.total;
            });
        },
        reLoadList () {
            this.query.pageIndex = 1
            this.getList();
        },
        lookCheckFunc () {
            this.lookCheck = !this.lookCheck
        },
        checkItem (item) {
            const { checkedArr } = this;

            let checkIndex = checkedArr.findIndex(cItem => cItem.id == item.id);
            if (checkIndex == -1) {
                console.log("不存在添加")
                checkedArr.push(item);
            } else {
                console.log("存在去除")
                checkedArr.splice(checkIndex, 1);
            }
            console.log(checkedArr)

            this.$set(this, "checkedArr", checkedArr)
        },
        submitFunc () {
            const { checkedArr } = this;
            this.$emit("change", checkedArr);
            this.show = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.list-box {
    padding-top: 20px;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .list-item {
        width: 80px;
        margin-right: 18px;
        margin-bottom: 20px;

        .img-box {
            width: 80px;
            height: 80px;

            position: relative;
            margin-bottom: 10px;

            >i {
                position: absolute;
                left: 0;
                bottom: 0;

                width: 80px;
                height: 80px;
                text-align: right;
                color: #6e9f21;
                border: 1px solid #6e9f21;
                padding: 10px;
                box-sizing: border-box;
            }
        }
    }
}

.bg-img {
    background-position: center center;
    background-size: cover;
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}
</style>
